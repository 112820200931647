"use strict";

import Form from './form';

export default function(el, callBack){
    if(el !== null) {
        el.addEventListener('submit', function (e) {
            e.preventDefault();
            var formValidation = new Form(this);
            formValidation.init();
            if (formValidation.formValid) {
                callBack(this);
            }
        }, false)
    }
};
