"use strict";

require('../sass/app.scss');

import FastClick from 'fastclick';
import locationOriginPolyfill from 'location-origin';
import validateForms from './src/validateForms';
import {validateAddingProductForms} from './src/validateAddingProductForms';
import Select from './src/select';
import Css from './src/css';
import DropDown from './src/dropdown';
import OffCanvas, {settings as offCanvasSettings} from './src/offCanvas';
import ToggleClass from './src/toggleClass';
import CartSummary from './src/cart-summary';
import cartCount from './src/cart-count';
import Ajax, {getParamsFromElement as getAjaxParamsFromElement} from './src/ajax';
import sampleRequested from './src/sample-requested';
import showPdfPreview from './src/show-pdf-preview';
import {parentUntilAttribute} from './src/traverse';
import Debounce from './src/debounce';
import Tabs from './src/tabs';
import Modal from './src/modal';
import StringOps from './src/stringOps';
import shippingOptionsDisplay from './src/shippingOptionsDisplay';
import Adz from './src/adz';
import productListDisplayChoices from './src/productListDisplayChoices';
import picturefill from 'picturefill';
import MenuAim from './src/menu-aim';
import quickOrderSinglePids from './src/quick-order-single-pid';

FastClick.attach(document.body);

var mainProductCategoriesMenu = Select.one('[data-main-product-categories-menu]');
if(mainProductCategoriesMenu !== null){
    var mainProductCategoriesMenuAim = new MenuAim(mainProductCategoriesMenu);
    mainProductCategoriesMenuAim.activateCallback = function(row){
        Css.addClass(row,'header__main__product-categories__nav-list-item--active');
    };
    mainProductCategoriesMenuAim.deactivateCallback = function(row){
        Css.removeClass(row,'header__main__product-categories__nav-list-item--active')
    };
    mainProductCategoriesMenuAim.exitMenuCallback = function(menu){
        return true;
    };
    mainProductCategoriesMenuAim.init();
}

var tabNavElements = Select.all('[data-tabs]');
if( tabNavElements !== null && tabNavElements.length ){
    for( var i=0, len=tabNavElements.length; i < len; i++ ){
        new Tabs( tabNavElements[i] );
    }
}

validateForms( '[data-js-validate]', false, function(formElement){ formElement.submit(); });
validateAddingProductForms( false );

var offCanvasElements = Select.all('['+ offCanvasSettings.wrapperAttribute +']'),
    initOffCanvasElements = function(){
        if(offCanvasElements !== null && offCanvasElements.length){
            for(var i= 0, len = offCanvasElements.length; i < len; i++){
                new OffCanvas(offCanvasElements[i]).init();
            }
        }
    };
initOffCanvasElements();

var toggleElements = Select.all('[data-toggle]'),
    initToggleElements = function(){
        if(toggleElements !== null){
            if(toggleElements.length){
                for(var i= 0, len=toggleElements.length; i < len; i++){
                    new ToggleClass(toggleElements[i]);
                }
            }
        }
    };
initToggleElements();

/**
 *  cart summary items
 */
var cartSummaryLargeToggleButton = Select.one('[data-button="cart-summary-large"]'),
    cartSummaryLargeParent = Select.one('[data-cart-summary-large]'),
    cartSummaryLargeOpenClassName = 'cart-summary__large__container--open';

if(cartSummaryLargeToggleButton !== null){
    var cartSummaryLargeAjaxParams = getAjaxParamsFromElement(cartSummaryLargeToggleButton),
        CartSummaryLarge = new CartSummary({
            url: cartSummaryLargeAjaxParams.url,
            fetchData: {},
            fetchMethod: cartSummaryLargeAjaxParams.method,
            summaryContainer: Select.one(cartSummaryLargeAjaxParams.resultsSelector),
            autoRefreshOnCartChange: true
        }),
        toggleCartSummaryLarge = function(forceClose){
            if(Css.hasClass(cartSummaryLargeParent, cartSummaryLargeOpenClassName) || forceClose) {
                Css.removeClass(cartSummaryLargeParent, cartSummaryLargeOpenClassName);
            }
            else {
                CartSummaryLarge.refreshCartSummary();
                Css.addClass(cartSummaryLargeParent, cartSummaryLargeOpenClassName);
            }
        };

    cartSummaryLargeParent.addEventListener('click', function(e){
        e.stopPropagation();
    }, false);
}

var cartSummaryOffCanvasToggleButton = Select.one('[data-button="off-canvas__cart-summary"]');
if(cartSummaryOffCanvasToggleButton !== null){
    var cartSummaryOffCanvasAjaxParams = getAjaxParamsFromElement(cartSummaryOffCanvasToggleButton),
        CartSummaryOffCanvas = new CartSummary({
            url: cartSummaryOffCanvasAjaxParams.url,
            fetchData: {},
            fetchMethod: cartSummaryOffCanvasAjaxParams.method,
            summaryContainer: Select.one(cartSummaryOffCanvasAjaxParams.resultsSelector),
            autoRefreshOnCartChange: false
        });

    cartSummaryOffCanvasToggleButton.addEventListener('click', function(e){
        e.preventDefault();
        CartSummaryOffCanvas.refreshCartSummary();
    }, false);
}

var bodyContainerToResolveIOSEventListenerBugOnBody = Select.id('bodyContainerToResolveIOSEventListenerBugOnBody');

if(bodyContainerToResolveIOSEventListenerBugOnBody !== null) {
    bodyContainerToResolveIOSEventListenerBugOnBody.addEventListener('click', function (e) {
        var buttonElement = parentUntilAttribute(e.target, 'data-button'),
            isDropDownButton = null;
        if (buttonElement !== null) {
            var buttonAttribute = buttonElement.getAttribute('data-button');
            switch (buttonAttribute) {
                case 'request-sample':
                    e.preventDefault();
                    sampleRequested(e, buttonElement);
                    break;
                case 'show-pdf-preview':
                    e.preventDefault();
                    showPdfPreview(e, buttonElement);
                    break;
                case 'cart-summary-large':
                    e.preventDefault();
                    toggleCartSummaryLarge();
                    break;
                case 'dropdown':
                    e.preventDefault();
                    isDropDownButton = true;
                    DropDown.init(buttonElement);
                    DropDown.toggle();
                    break;
                case 'tab':
                    console.log('tab');
                    break;
                case 'modal':
                    e.preventDefault();
                    onModalButtonClick(buttonElement);
                    break;
                case 'mail-to':
                    e.preventDefault();
                    var emailAddress = e.target.innerHTML.trim() || e.target.innerHTML;
                    emailAddress = StringOps.reverseString(emailAddress);
                    if(emailAddress !== null)
                        window.location = 'mailto:' + emailAddress;
                    break;
                case 'expedited-shipping':
                    e.preventDefault();
                    shippingOptionsDisplay(e, buttonElement);
                    break;
                case 'a-dz':
                    Adz.init(e, buttonElement);
                    Adz.clicked();
                    break;
                case 'list-display-choice':
                    productListDisplayChoices.init(e, buttonElement);
                    break;
                case 'show-search-form':
                case 'close-search-form':
                    var searchFormContainerId = buttonElement.getAttribute('data-search-form-container');
                    if( searchFormContainerId ){
                        var searchFormContainer = Select.one('[data-search-form-container-id="'+searchFormContainerId+'"]');
                        if( searchFormContainer !== null ){
                            var searchFormContainerSearchInput = Select.one('input[name="search"]', searchFormContainer);
                            if(Css.hasClass( searchFormContainer, 'header__main__search__column--show-for-small' )){
                                Css.removeClass( searchFormContainer, 'header__main__search__column--show-for-small' );
                                if(searchFormContainerSearchInput !== null){
                                    searchFormContainerSearchInput.blur();
                                }
                            }
                            else{
                                Css.addClass( searchFormContainer, 'header__main__search__column--show-for-small' );
                                if(searchFormContainerSearchInput !== null){
                                    searchFormContainerSearchInput.focus();
                                }
                            }
                        }
                    }
                    break;
            }
        }
        else {
            if (isDropDownButton === null) {
                DropDown.closeAllOpenExceptThisDropdown(null);
            }
            if (typeof(toggleCartSummaryLarge) === 'function')
                toggleCartSummaryLarge(true);
        }
    }, false);
}

window.addEventListener('resize', Debounce(function(){
    DropDown.reCalcOpenDropDown();
}, 250), false);

var onModalButtonClick = function(buttonElement){
    if(buttonElement !== null){
        var buttonModal = buttonElement.getAttribute('data-modal-content');
        if(buttonModal !== null){
            var theModal = Select.one('[data-modal="' + buttonModal + '"]');
            if(theModal !== null){
                Modal.closeOnBlur = true;
                Modal.btnClose.callback = function(e){ e.preventDefault() };
                Modal.btnCancel.callback = function(e){ e.preventDefault() };
                Modal.init();
                Modal.setCustomContent(theModal.innerHTML);
                Modal.open();
            }
        }
    }
};


var quickOrderSinglPidForms = Select.all('[data-quick-order-single-pid-form]');

quickOrderSinglePids(quickOrderSinglPidForms);

var cartCountElements = Select.all('[data-cart-count]');
if( cartCountElements !== null && cartCountElements.length ) {
    cartCount.init({
        cartCountElements: cartCountElements,
        url: window.location.origin + '/api/v1/carts/line-items/count'
    });
}

// this can be used to reset cart last modified date if needed
//Ajax('GET', window.location.origin + '/api/v1/carts/touch', {}, function (response) {});
