"use strict";

import Validation from './validation';
import Easing from './easing';
import Animate from './animate';

const requestAnimFrame = Animate.requestAnimFrame;

export default {
    scrollTop: function(){
        return  window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.parentNode.scrollTop ||
                document.body.scrollTop;
    },
    scrollBottom: function(){
        Math.max( document.body.scrollHeight, document.body.offsetHeight,
            document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight );
    },
    scrollLeft: function(){
        return  window.pageXOffset ||
                document.documentElement.scrollLeft ||
                document.body.parentNode.scrollLeft ||
                document.body.scrollLeft;
    },
    // it's difficult to detect the scrolling element, just move them all
    move: function(amount) {
        document.documentElement.scrollTop = amount;
        document.body.parentNode.scrollTop = amount;
        document.body.scrollTop = amount;
    },
    scrollTo: function(to, callback, duration) {
        var start = this.scrollTop(),
            change = to - start,
            initialTime = Date.now(),
            //increment = 20,
            self = this,
            duration = (typeof(duration) === 'undefined') ? 300 : duration;
        duration = (typeof(duration) === 'undefined') ? 500 : duration;
        var animateScroll = function() {
            // increment the time
            var currentTime = Date.now() - initialTime;
            // find the value with the quadratic in-out easing function
            // and then move the document.body
            self.move(Easing.easeInOutQuad(currentTime, start, change, duration));
            // do the animation unless its over
            if (currentTime <= duration) {
                requestAnimFrame(animateScroll);
            } else {
                if (Validation.isFunction(callback)) {
                    // the animation is done so lets callback
                    callback();
                }
            }
        };
        animateScroll();
    }
};
