import Position from './position';
import Size from './size';
import Select from './select';

export default MenuAim;

// Detect if the user is moving towards the currently activated
// submenu.
//
// If the mouse is heading relatively clearly towards
// the submenu's content, we should wait and give the user more
// time before activating a new row. If the mouse is heading
// elsewhere, we can immediately activate a new row.
//
// We detect this by calculating the slope formed between the
// current mouse location and the upper/lower right points of
// the menu. We do the same for the previous mouse location.
// If the current mouse location's slopes are
// increasing/decreasing appropriately compared to the
// previous's, we know the user is moving toward the submenu.
//
// Note that since the y-axis increases as the cursor moves
// down the screen, we are looking for the slope between the
// cursor and the upper right corner to decrease over time, not
// increase (somewhat counterintuitively).
const slope = function(a, b) {
    return (b.y - a.y) / (b.x - a.x);
};

function MenuAim(menuEl){
    this.menu = menuEl || null;
    this.menuOffset = {};
    this.menuOuterWidth = 0;
    this.menuOuterHeight = 0;
    this.activeRow = null;
    this.mouseLocs = [];
    this.lastDelayLoc = null;
    this.timeoutId = null;
    this.rowSelector = "[data-menu-list-item]";
    this.submenuSelector = "*";
    this.submenuDirection = "right";
    this.tolerance = 80;//75; // bigger = more forgivey when entering submenu
    this.enterCallback = function(){};
    this.exitCallback = function(){};
    this.activateCallback = function(){};
    this.deactivateCallback = function(){};
    this.exitMenuCallback = function(){};
    this.MOUSE_LOCS_TRACKED = 3;
    this.DELAY = 300;
}

MenuAim.prototype = {
    init: function(opts){
        if(this.menu !== null){
            /**
             * Hook up initial menu events
             */
            const self = this;
            this.menu.addEventListener('mouseleave', function(e){
                self.mouseleaveMenu();
            }, false);
            const rows = Select.all(this.rowSelector, this.menu);
            if(rows !== null && rows.length){
                for( let i= 0, len=rows.length; i < len; i++ ){
                    this.addRowEventListeners(rows[i]);
                }
            }
            //window.document or ...
            this.menu.addEventListener('mousemove', function(e){
                self.mousemoveDocument(e);
            }, true);
        }
    },
    addRowEventListeners: function(row){
        const self = this;
        row.addEventListener('mouseenter', function(e){
            self.mouseenterRow(this);
        }, false);
        row.addEventListener('mouseleave', function(e){
            self.mouseleaveRow(this);
        }, false);
        row.addEventListener('click', function(e){
            self.clickRow(this);
        }, false);
    },
    /**
     * Keep track of the last few locations of the mouse.
     */
    mousemoveDocument: function(e) {
        this.mouseLocs.push({x: e.pageX, y: e.pageY});

        if (this.mouseLocs.length > this.MOUSE_LOCS_TRACKED) {
            this.mouseLocs.shift();
        }
    },
    /**
     * Cancel possible row activations when leaving the menu entirely
     */
    mouseleaveMenu: function() {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
        // If exitMenu is supplied and returns true, deactivate the
        // currently active row on menu exit.
        if (this.exitMenuCallback(this.menu)) {
            if (this.activeRow) {
                this.deactivateCallback(this.activeRow);
            }
            this.activeRow = null;
        }
    },
    /**
     * Trigger a possible row activation whenever entering a new row.
     */
    mouseenterRow: function(row) {
        if (this.timeoutId) {
            // Cancel any previous activation delays
            clearTimeout(this.timeoutId);
        }

        this.enterCallback(row);
        this.possiblyActivate(row);
    },
    mouseleaveRow: function(row) {
        this.exitCallback(row);
    },
    /*
     * Immediately activate a row if the user clicks on it.
     */
    clickRow: function(row) {
        this.activate(row);
    },
    activate: function(row) {
        if (row == this.activeRow) {
            return;
        }
        if (this.activeRow) {
            this.deactivateCallback(this.activeRow);
        }
        this.activateCallback(row);
        this.activeRow = row;
    },
    /**
     * Possibly activate a menu row. If mouse movement indicates that we
     * shouldn't activate yet because user may be trying to enter
     * a submenu's content, then delay and check again later.
     */
    possiblyActivate: function(row) {
        const delay = this.activationDelay();

        if (delay) {
            const self = this;
            this.timeoutId = setTimeout(function() {
                self.possiblyActivate(row);
            }, delay);
        } else {
            this.activate(row);
        }
    },
    getOffset: function(el){
        if( el !== null )
            return Position.getOffsetRect(el);
    },
    /**
     * Return the amount of time that should be used as a delay before the
     * currently hovered row is activated.
     *
     * Returns 0 if the activation should happen immediately. Otherwise,
     * returns the number of milliseconds that should be delayed before
     * checking again to see if the row should be activated.
     */
    matches: function(el, selector) {
        return (el.matches || el.matchesSelector || el.msMatchesSelector || el.mozMatchesSelector || el.webkitMatchesSelector || el.oMatchesSelector).call(el, selector);
    },
    activationDelay: function() {
        if (!this.activeRow || !this.matches(this.activeRow, this.submenuSelector)) {
            // If there is no other submenu row already active, then
            // go ahead and activate immediately.
            return 0;
        }

        this.menuOffset = this.getOffset(this.menu);
        this.menuOuterWidth = Size.outerWidth(this.menu);
        this.menuOuterHeight =  Size.outerHeight(this.menu);
            const upperLeft = {
                x: this.menuOffset.left,
                y: this.menuOffset.top - this.tolerance
            };
            const upperRight = {
                x: this.menuOffset.left + this.menuOuterWidth,
                y: upperLeft.y
            };
            const lowerLeft = {
                x: this.menuOffset.left,
                y: this.menuOffset.top + this.menuOuterHeight + this.tolerance
            };
            const lowerRight = {
                x: this.menuOffset.left + this.menuOuterWidth,
                y: lowerLeft.y
            };
            const loc = this.mouseLocs[this.mouseLocs.length - 1];
            let prevLoc = this.mouseLocs[0];

        if (!loc) {
            return 0;
        }

        if (!prevLoc) {
            prevLoc = loc;
        }

        if (prevLoc.x < this.menuOffset.left || prevLoc.x > lowerRight.x ||
            prevLoc.y < this.menuOffset.top || prevLoc.y > lowerRight.y) {
            // If the previous mouse location was outside of the entire
            // menu's bounds, immediately activate.
            return 0;
        }

        if (this.lastDelayLoc &&
            loc.x == this.lastDelayLoc.x && loc.y == this.lastDelayLoc.y) {
            // If the mouse hasn't moved since the last time we checked
            // for activation status, immediately activate.
            return 0;
        }

        let decreasingCorner = upperRight,
            increasingCorner = lowerRight;

        // Our expectations for decreasing or increasing slope values
        // depends on which direction the submenu opens relative to the
        // main menu. By default, if the menu opens on the right, we
        // expect the slope between the cursor and the upper right
        // corner to decrease over time, as explained above. If the
        // submenu opens in a different direction, we change our slope
        // expectations.
        if (this.submenuDirection == "left") {
            decreasingCorner = lowerLeft;
            increasingCorner = upperLeft;
        } else if (this.submenuDirection == "below") {
            decreasingCorner = lowerRight;
            increasingCorner = lowerLeft;
        } else if (this.submenuDirection == "above") {
            decreasingCorner = upperLeft;
            increasingCorner = upperRight;
        }

        const decreasingSlope = slope(loc, decreasingCorner),
            increasingSlope = slope(loc, increasingCorner),
            prevDecreasingSlope = slope(prevLoc, decreasingCorner),
            prevIncreasingSlope = slope(prevLoc, increasingCorner);

        if (decreasingSlope < prevDecreasingSlope &&
            increasingSlope > prevIncreasingSlope) {
            // Mouse is moving from previous location towards the
            // currently activated submenu. Delay before activating a
            // new menu row, because user may be moving into submenu.
            this.lastDelayLoc = loc;
            return this.DELAY;
        }

        this.lastDelayLoc = null;
        return 0;
    }
};
