"use strict";

import Select from './select';
import Css from './css';
import Size from './size';
import Position from './position';
import Scroll from './scroll';
import Debounce from './debounce';

var scrollTolerance = 40,
    expandedClassName = 'product-list__preview--expanded',
    setProductPreviewButtons = function(context) {
        var contextEl = context || document,
            productPreviewButtons = Select.all('[data-button="product-preview"]', contextEl);

        if(productPreviewButtons !== null && productPreviewButtons.length){
            for(var i = 0, len = productPreviewButtons.length; i < len; i++ ){
                productPreviewButtons[i].addEventListener('click', function(e){
                    e.preventDefault();

                    if(this.hasAttribute('data-button-disabled'))
                        return false;

                    var preview = this.getAttribute('data-preview-id'),
                        previewElement = Select.one('[data-preview="'+ preview +'"]'),
                        previewElementOffsetRectangle = Position.getOffsetRect(previewElement),
                        initialScrollTop = Scroll.scrollTop(),
                        triggerScrollTo = (
                            previewElementOffsetRectangle.top >= (initialScrollTop + scrollTolerance )
                            || previewElementOffsetRectangle.top <= (initialScrollTop - scrollTolerance)
                        );

                    if(this.hasAttribute('data-close-preview') && previewElement !== null) {
                        hideProductPreview(previewElement);
                        return false;
                    }

                    if( !Css.hasClass(previewElement, expandedClassName) ){
                        if(triggerScrollTo) {
                            hideAllProductPreviewsExceptSelectedPreview(previewElement);
                            showProductPreview(previewElement, true);
                        }
                        else{
                            showProductPreview(previewElement, false);
                            hideAllProductPreviewsExceptSelectedPreview(previewElement);
                        }
                    }

                }, false);
            }
        }
    },

    showProductPreview = function(el, triggerScroll) {
        var outerHeight = Size.outerHeight(el),
            outerHeightPlusMargins = Size.outerHeightPlusMargins(el),
            previewContainer = Select.one('[data-preview-container]', el),
            s = el.style;
        if(previewContainer !== null){
            s.height = outerHeight + 'px';
            Css.addClass(el , expandedClassName);
            var previewContainerOuterHeight = Size.outerHeight(previewContainer);
            s.height = outerHeight + previewContainerOuterHeight + 'px';
            if(triggerScroll) {
                var scrollToPosition = Position.getOffsetRect(el);
                Scroll.scrollTo(scrollToPosition.top, function () {
                }, 200);
            }
        }
    },

    hideProductPreview = function(el){
        el.style.height = '';
        Css.removeClass(el, expandedClassName);
    },

    hideAllProductPreviewsExceptSelectedPreview = function(selectedElement){
        var allProductPreviews = Select.all('.' + expandedClassName + '[data-preview]');
        if(allProductPreviews !== null && allProductPreviews.length){
            for(var i = 0, len = allProductPreviews.length; i < len; i++ ) {
                if( selectedElement == null || !allProductPreviews[i].isEqualNode(selectedElement) ) {
                    hideProductPreview(allProductPreviews[i]);
                }
            }
        }
    },

    reCalcProductPreviewOnResize = Debounce(function() {
        var expandedElement = Select.one( '.' + expandedClassName );
        if(expandedElement !== null){
            expandedElement.style.height = '';
            showProductPreview(expandedElement, false);
        }
    }, 250),

    addResizeEventListener = function(){
        window.addEventListener('resize', reCalcProductPreviewOnResize);
    },

    init = function(context){
        setProductPreviewButtons(context);
        addResizeEventListener();
    };

export { init, hideAllProductPreviewsExceptSelectedPreview };
