"use strict";

export default {
    toInt: function (str) {
        return parseInt(str);
    },
    htmlEncode: function( str ) {
        // need to reuse div so we don't make a bunch of these encoding the string
        return document.createElement('div').appendChild(document.createTextNode(str)).parentNode.innerHTML;
    }
};

