"use strict";

import Select from './select';
import validateForm from './validateForm';

export default function(selector, context, callback) {
    var formsToValidate = Select.all(selector, context);
    if (formsToValidate !== null) {
        for (var i = 0, len=formsToValidate.length; i < len; i++) {
            validateForm(formsToValidate[i], callback);
        }
    }
};
