"use strict";

import Select from './select';
import Css from './css';
const settings = {
    parentElementAttributeName: 'data-tab',
    tabAttributeName: 'data-tab-content',
    tabContentAttributeName: 'data-tab-content-id',
    tabOpenClass: 'tab__list-item--open',
    tabContentOpenClass: 'tab__content--open'
};

export default Tabs;

function Tabs(parentElement, collapsable){
    this.tabsParentElement = parentElement;
    this.collapsable = collapsable || null;
    this.tabItems = null;
    this.init();
}

Tabs.prototype = {
    init: function(){
        if(this.tabsParentElement !== null){
            var tabItems = {},
                self = this;
            this.tabItems = this.tabsParentElement.children;
            if(this.tabItems !== null && this.tabItems.length){
                for(var i = 0, len = this.tabItems.length; i < len; i++){
                    // just in case the children return other nodes
                    if( this.tabItems[i].nodeType == 1 ){
                        var header = Select.one('[' + settings.tabAttributeName + ']',this.tabItems[i]),
                            contentId = header !== null ? header.getAttribute(settings.tabAttributeName) : '',
                            content = Select.one('[' + settings.tabContentAttributeName + '="'+ contentId +'"]'),
                            tabItemObject = {
                                item: this.tabItems[i],
                                header: header,
                                contentId: contentId,
                                content: content
                            };
                        tabItems[tabItemObject.contentId] = tabItemObject;
                        header.addEventListener('click', function(e){
                            e.preventDefault();
                            var tabContentId = e.target.getAttribute(settings.tabAttributeName);
                            self.closeAllExcept(tabContentId);
                            self.toggle(tabContentId);
                        }, false);
                    }
                }
            }
            this.tabItems = tabItems;
        }
    },
    isOpen: function(item) {
        return Css.hasClass(item.item, settings.tabOpenClass);
    },
    open: function(item){
        if(typeof item === 'object'){
            Css.addClass(item.item, settings.tabOpenClass);
            Css.addClass(item.content, settings.tabContentOpenClass);
        }
    },
    close: function(item){
        if(typeof item === 'object'){
            Css.removeClass(item.item, settings.tabOpenClass);
            Css.removeClass(item.content, settings.tabContentOpenClass);
        }
    },
    closeAllExcept: function(itemId){
        for (var key in this.tabItems) {
            if (this.tabItems.hasOwnProperty(key) && this.tabItems[key].contentId !== itemId) {
                this.close(this.tabItems[key]);
            }
        }
    },
    toggle: function(itemId){
        if( typeof this.tabItems[itemId].content !== 'undefined') {
            var item = this.tabItems[itemId],
                isOpen = this.isOpen(item);
            if (isOpen && this.collapsable)
                this.close(item);
            else if(!isOpen) {
                this.open(item);
            }
        }
    }
};
