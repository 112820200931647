"use strict";

import Emitter from 'tiny-emitter';
const emitter = new Emitter();

// had to make this global for now. will fix later
if(!window.eventEmitter)
    window.eventEmitter = emitter;

export default window.eventEmitter;
