"use strict";

export default function (method, url, data, success, error) {
    var gCsrfToken = window.gCsrfToken || '',//this is a global that would have been set at the view level else empty
        method = (method || "GET"),
        params = typeof data == 'string' ? data : Object.keys(data).map(
        function(k){ return encodeURIComponent(k) + '=' + encodeURIComponent(data[k]) }
    ).join('&');

    var xhr = window.XMLHttpRequest ? new XMLHttpRequest() : new ActiveXObject("Microsoft.XMLHTTP");
    xhr.open(method, url);
    xhr.onreadystatechange = function() {
        if (xhr.readyState>3){
            if(xhr.status >= 200 && xhr.status < 400) {
                if (typeof(success) === 'function')
                    success(xhr.responseText);
            }
            else{
                if(xhr.status === 401)
                    location.reload();
                if(typeof(error) === 'function')
                    error(xhr.status, xhr.responseText);
            }
        }
        else{
            // still processing (readyState is 0-3)
        }
    };
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    if(method !== 'GET') {
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        var xcsrf = gCsrfToken;
        xhr.setRequestHeader('X-CSRF-TOKEN', xcsrf );
    }
    xhr.send(params);
    return xhr;
};

export const getParamsFromElement = function(el){
    return {
        url: el.getAttribute('data-ajax') || el.getAttribute('rel') || '',
        method: el.getAttribute('data-ajax-method') || 'GET',
        resultsSelector: '[data-ajax-id="'+ el.getAttribute('data-ajax-to') +'"]',
        showProgressSelector: '[data-ajax-show-progress-id="'+ el.getAttribute('data-ajax-show-progress') +'"]'
    };
};

