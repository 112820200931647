"use strict";

export default function(e, el){
    if(el !== null){
        e.preventDefault();
        var url = el.getAttribute('href'),
            windowName = 'pdfPreview',
            windowParams = el.getAttribute('data-window-params') || 'height=300,width=300',
            windowObj = window.open(url,windowName,windowParams);
        windowObj.focus();
    }
};
