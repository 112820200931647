"use strict";

import Select from './select';
import Css from './css';
const settings = {
    wrapperAttribute: "data-off-canvas",
    directionAttribute: "data-off-canvas-direction",
    contentContainerAttribute: 'data-off-canvas-container',
    menuAttribute: 'data-off-canvas-menu',
    toggleButtonsAttribute: 'data-off-canvas-toggle',
    closeButtonsAttribute: 'data-off-canvas-close',
    contentContainerOpenAttribute: 'data-off-canvas-open',
    openClass: 'off-canvas__container--open',
    closeActionClassForToggleButton: 'off-canvas__toggle-button__action--close-canvas'
};

export default OffCanvas;
export {settings};

function OffCanvas(wrapperEl){
    this.wrapperEl = wrapperEl || null;
    if( this.wrapperEl !== null ) {
        this.offCanvasId = this.wrapperEl.getAttribute(settings.wrapperAttribute);
        this.offCanvasDirection = this.wrapperEl.getAttribute(settings.directionAttribute) || null;
        this.openClass = this.getOpenClassName(this.offCanvasDirection);
        this.contentContainerEl = Select.one('['+ settings.contentContainerAttribute +'="'+ this.offCanvasId +'"]', this.wrapperEl );
        this.contentContainerOverlay = document.createElement('a');
        Css.addClass(this.contentContainerOverlay, 'off-canvas__container__overlay');
        this.menuEl = Select.one('['+ settings.menuAttribute + '="'+ this.offCanvasId +'"]', this.wrapperEl );
        this.toggleButtons = Select.all('['+ settings.toggleButtonsAttribute + '="'+ this.offCanvasId +'"]');
        this.closeButtons = Select.all('['+ settings.closeButtonsAttribute + '="'+ this.offCanvasId +'"]');
    }
}

OffCanvas.prototype = {
    init: function(){
        var self = this;
        if(this.toggleButtons !== null && this.toggleButtons.length){
            for(var i= 0, len=this.toggleButtons.length; i < len; i++){
                this.toggleButtons[i].addEventListener('click', function(e){
                    e.preventDefault();
                    self.toggle();
                }, false);
            }
        }
        if(this.closeButtons !== null && this.closeButtons.length){
            for(var ii= 0, len2=this.closeButtons.length; ii < len2; ii++){
                this.closeButtons[ii].addEventListener('click', function(e){
                    e.preventDefault();
                    self.close();
                }, false);
            }
        }
        this.contentContainerOverlay.addEventListener('click', function(e){
            e.preventDefault();
            e.stopPropagation();
            self.close();
        },false);
    },
    open: function(){
        this.contentContainerEl.setAttribute(settings.contentContainerOpenAttribute,'');
        Css.addClass(this.contentContainerEl, this.openClass);
        this.forceRedraw(this.contentContainerEl);
        this.contentContainerEl.appendChild(this.contentContainerOverlay);
        for(var i= 0, len=this.toggleButtons.length; i < len; i++){
            Css.addClass(this.toggleButtons[i], settings.closeActionClassForToggleButton);
        }
    },
    close: function(){
        this.contentContainerEl.removeAttribute(settings.contentContainerOpenAttribute);
        Css.removeClass(this.contentContainerEl, this.openClass);
        for(var i= 0, len=this.toggleButtons.length; i < len; i++){
            Css.removeClass(this.toggleButtons[i], settings.closeActionClassForToggleButton);
        }
        this.removeContentContainerOverlay();
        //this.removeAnyFocusFromClick();
    },
    removeContentContainerOverlay: function(){
        if(this.contentContainerOverlay.parentNode !== null) {
            this.forceRedraw(this.contentContainerEl);
            var self = this,
                destroy = function(){
                    self.contentContainerOverlay.parentNode.removeChild(self.contentContainerOverlay);
                },timeoutID = window.setTimeout(destroy, 150);
        }
    },
    forceRedraw(el){
        el.offsetHeight;
    },
    removeAnyFocusFromClick: function(){
        if (document.activeElement != document.body)
            document.activeElement.blur();
    },
    toggle: function(){
        if(this.contentContainerEl.hasAttribute(settings.contentContainerOpenAttribute))
            this.close();
        else
            this.open();
    },
    getOpenClassName: function(direction){
        return settings.openClass + (direction ? '--' + direction : '' );
    }
};

