"use strict";

import Css from './css';
import Ajax from './ajax';
import Emitter from './globalEmitter';

export default {
    cartCountElements: null,
    cartCount: 0,
    url: null,
    fetchMethod: 'GET',
    init: function(params){
        this.cartCountElements = params.cartCountElements || null;
        this.url = params.url || null;
        this.fetchMethod = params.fetchMethod || 'GET';
        this.updateCartCount();
        var self = this;
        Emitter.on('cart-change', function () {
            self.updateCartCount();
        });
    },
    fetch: function(){
        var self = this;
        Ajax(this.fetchMethod, this.url, {}, function (response) {
            var responseJson = JSON.parse(response);
            if (responseJson.data.hasOwnProperty('count')) {
                self.cartCount = parseInt(responseJson.data.count);
                self.onFetchCartCount(responseJson);
            }
            else{
                //console.log('error fetching cart count', response);
            }
        },
        function(status, responseText){
            //
        });
    },
    onFetchCartCount: function(response){
        this.updateCartCountDisplay();
    },
    updateCartCount: function(){
        this.fetch();
    },
    updateCartCountDisplay: function(){
        if(this.cartCountElements !== null){
            if( this.cartCountElements.length ){
                for(var i = 0, len = this.cartCountElements.length; i < len; i++){
                    if(this.cartCount > 0) {
                        this.cartCountElements[i].innerHTML = this.cartCount;
                        Css.addClass(this.cartCountElements[i], this.cartCountElements[i].getAttribute('data-toggle-class'));
                    }
                    else{
                        this.cartCountElements[i].innerHTML = '';
                        Css.removeClass(this.cartCountElements[i], this.cartCountElements[i].getAttribute('data-toggle-class'));
                    }
                }
            }
        }
    }
};
