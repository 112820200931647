"use strict";

import Select from './select';
import Position from './position';
import Css from './css';
import {parentUntilAttribute, parentUntilQualifyingPosition} from './traverse';
import Size from './size';

export default {
    dropDownButton: null,
    dropDownId: 0,
    dropDown: null,
    dropDownQualifyingParent: null,
    defaultDropDownPosition: 'bottom',
    dropDownPosition: 'bottom',
    dropDownButtonAttributeName: 'data-dropdown',
    dropDownButtonOpenClass: 'dropdown--open',
    dropDownOpenClass: 'dropdown--open',
    dropDownOpenAttribute: 'data-dropdown-content',
    dropDownContentAttributeName: 'data-dropdown-id',
    init: function(el) {
        this.dropDownButton = el;
        if(this.dropDownButton !== null) {
            var self = this;
            this.dropDownId = this.getDropDownIdFromElement(this.dropDownButton);
            this.dropDown = Select.one('[' + this.dropDownContentAttributeName + '="' + this.dropDownId + '"]');
            if (this.dropDown !== null) {
                this.dropDownQualifyingParent = this.getDropDownQualifyingParent();
                this.dropDownPosition = this.getDropDownPosition(this.dropDownButton);
                this.dropDown.addEventListener('click', function (e) {
                    var button = parentUntilAttribute(e.target, 'data-button');
                    if (button === null)
                        e.stopPropagation();
                }, false);
            }
        }
    },

    toggle: function(el, button){
        if(this.isOpen(el))
            this.close(el);
        else
            this.open(el, button);
    },

    open: function(el, button) {
        var el = el || this.dropDown,
            button = button || this.dropDownButton,
            dropDownPosition = this.getDropDownPosition(button),
            dropDownQualifyingParent =  parentUntilQualifyingPosition(el.parentNode, Css.positionQualifyingArray),
            dropDownCoordinates = Position.coordinatesToPositionAbsolutlyNextToElement(button, dropDownQualifyingParent, dropDownPosition),
            documentWidth = document.documentElement.offsetWidth,
            dropDownListeners = this.selectAllDropDownListeners( this.getDropDownIdFromElement( button ) );
        this.closeAllOpenExceptThisDropdown(this.dropDownId);
        Css.setStyle(el, { position: 'absolute', top: dropDownCoordinates.top, right: dropDownCoordinates.right, bottom: dropDownCoordinates.bottom, left: dropDownCoordinates.left });
        Css.addClass(el, this.dropDownOpenClass);
        var elementWidth = Size.outerWidthPlusMargins(el),
            leftCoordinate = parseInt( dropDownCoordinates.left),
            amountPastRightSideOfDocument = documentWidth - (leftCoordinate + elementWidth);
        if( amountPastRightSideOfDocument < 0 ) {
            Css.setStyle(el, { left: leftCoordinate + amountPastRightSideOfDocument + 'px' });
        }
        Css.addClass(button, this.dropDownButtonOpenClass);
        this.updateDropDownListenerDropDownClasses( dropDownListeners, 'open' );
        button.setAttribute('aria-expanded', 'true');
        el.setAttribute(this.dropDownOpenAttribute, 'visible');
        el.setAttribute('aria-hidden', 'false');
    },

    getDropDownQualifyingParent:  function(el){
        var el = el || this.dropDown;
        return this.dropDownQualifyingParent || parentUntilQualifyingPosition(el.parentNode, Css.positionQualifyingArray);
    },

    isOpen: function(el){
        var el = el || this.dropDown;
        if(el !== null){
            return el.getAttribute(this.dropDownOpenAttribute) == 'visible';
        }
        return false;
    },

    close: function(el, button) {
        if(this.isOpen(el)) {
            var el = el || this.dropDown,
                button = button || this.dropDownButton,
                dropDownListeners = this.selectAllDropDownListeners( this.getDropDownIdFromElement( button ) );
            Css.setStyle(el, {position: '', top: '', right: '', bottom: '', left: ''});
            Css.removeClass(el, this.dropDownOpenClass);
            Css.removeClass(button, this.dropDownButtonOpenClass);
            this.updateDropDownListenerDropDownClasses( dropDownListeners, 'close' );
            button.setAttribute('aria-expanded', 'false');
            el.setAttribute(this.dropDownOpenAttribute, 'hidden');
            el.setAttribute('aria-hidden', 'true');
        }
    },

    closeAllOpenExceptThisDropdown: function(dropdownId) {
      var openDropDowns = Select.all('['+ this.dropDownOpenAttribute +'="visible"]');
        if(openDropDowns !== null && openDropDowns.length){
            for( var i = 0, len = openDropDowns.length; i < len; i++){
                if(openDropDowns[i].getAttribute( this.dropDownContentAttributeName ) !== dropdownId) {
                    var button = Select.one('['+ this.dropDownButtonAttributeName +'="'+ openDropDowns[i].getAttribute( this.dropDownContentAttributeName ) +'"]');
                    if(button !== null && openDropDowns[i] !== null)
                        this.close(openDropDowns[i], button);
                }
            }
        }
    },

    getDropDownPosition: function(el) {
        if (el === null)
            return this.defaultDropDownPosition;
        else
            return el.getAttribute('data-dropdown-position') || this.defaultDropDownPosition;
    },

    reCalcOpenDropDown: function(){
        if(this.isOpen())
            this.open();
    },

    getDropDownIdFromElement( el ){
        return el !== null ? el.getAttribute(this.dropDownButtonAttributeName) : '';
    },

    selectAllDropDownListeners( dropDownId ){
        return Select.all( '[data-dropdown-listen="'+ dropDownId +'"]' );
    },

    updateDropDownListenerDropDownClasses( elements, action ){
        if( elements !== null && elements.length ){
            for ( var i = 0, len=elements.length; i < len; i++ ){
                if( action === 'open' )
                    Css.addClass(elements[i], this.dropDownOpenClass);
                if( action === 'close' )
                    Css.removeClass(elements[i], this.dropDownOpenClass);
            }
        }
    }
};
