"use strict";

import Select from './select';
import Css from './css';
import Ajax from './ajax';
import {parentUntilAttribute} from './traverse';
import {hideAllProductPreviewsExceptSelectedPreview} from './productListPreviews';

export default {
    displayChoice: null,
    url: window.location.origin + '/api/v1/search-results/settings/list-display-choice',
    init: function(e, btn){
        e.preventDefault();
        this.displayChoice = btn.getAttribute('data-display-choice-value');
        if(this.displayChoice !== null){
            var displayChoiceListParent = parentUntilAttribute(e.target, 'data-display-choice-list');
            if(displayChoiceListParent !== null){
                var displayChoiceParentId = displayChoiceListParent.getAttribute('data-display-choice-parent'),
                    displayChoiceParent = null;
                if(displayChoiceParentId)
                    displayChoiceParent = Select.one('[data-display-choice-parent-id="' + displayChoiceParentId + '"]');
                else
                    displayChoiceParent = displayChoiceListParent.parentNode;
                if(displayChoiceParent !== null)
                    var displayChoiceClassElements = Select.all('[data-display-choice-class-element]',displayChoiceParent);
                this.setDisplayChoiceClassOnElements(displayChoiceClassElements);
                var displayChoiceListItems = Select.all('[data-display-choice-list-item]',displayChoiceListParent);
                this.setChoiceListItemsSelectedClass(displayChoiceListItems);
                if(this.displayChoice == 'large-list')
                    hideAllProductPreviewsExceptSelectedPreview();
                this.pushChoice(this.displayChoice);
            }
        }
    },
    setDisplayChoiceClassOnElements: function(elements){
        if(elements !== null && elements.length) {
            for (var i = 0, len = elements.length; i < len; i++) {
                Css.removeClass(elements[i], 'small-list');
                Css.removeClass(elements[i], 'medium-list');
                Css.removeClass(elements[i], 'large-list');
                Css.addClass(elements[i], this.displayChoice);
            }
        }
    },
    pushChoice: function(displayChoiceValue){
        if(this.url && this.displayChoice){
            Ajax('PUT', this.url, {"setting_value": displayChoiceValue, "format": "data"},
                function (response) {});
        }
    },
    setChoiceListItemsSelectedClass: function(listItems){
        if(listItems !== null && listItems.length){
            for(var i = 0, len = listItems.length; i < len; i++ ) {
                var listItemsValue = listItems[i].getAttribute('data-display-choice-list-item');
                if(listItemsValue == this.displayChoice)
                    Css.addClass(listItems[i], 'selected');
                else
                    Css.removeClass(listItems[i], 'selected');
            }
        }
    }
};
