"use strict";

import Css from './css';
import Position from './position';
import {parentUntilQualifyingPosition} from './traverse';

export default Loading;

function Loading() {
    this.loadingElementClassName = 'loading';
    this.loadingElement = null;
    this.init();
}

Loading.prototype = {
    init: function(){
      this.setLoadingElement();
    },
    getLoadingElement: function(){
        if(this.loadingElement !== null)
            return this.loadingElement;
        else
            return this.setLoadingElement();
    },
    setLoadingElement: function(){
        var loadingElement = document.createElement('div');
        Css.addClass(loadingElement, this.loadingElementClassName);
        this.loadingElement = loadingElement;
    },
    setLoadingElementContent: function(content){
        this.loadingElement.innerHTML = content;
    },
    insertLoadingElement: function(parentElement) {
        var appendToElement = parentElement || document.body,
            qualifyingParent =  parentUntilQualifyingPosition(appendToElement, Css.positionQualifyingArray),
            coordinates = Position.coordinatesToPositionAbsolutlyNextToElement(appendToElement, qualifyingParent, 'over');
        if(this.loadingElement !== null){
            appendToElement.appendChild(this.loadingElement);
            Css.setStyle(this.loadingElement, { position: 'absolute', top: coordinates.top, right: coordinates.right, bottom: coordinates.bottom, left: coordinates.left });
        }
    },
    removeLoadingElement: function(){
        if(this.loadingElement !== null)
            this.loadingElement.parentNode.removeChild(this.loadingElement);
    }
};

