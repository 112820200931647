"use strict";

import Ajax from './ajax';

export default {
    url: window.location.origin + '/api/v1/carts/samples',
    addToCart: function(productId, callback){
        if(typeof(callback) === 'function')
            this.onSuccess = callback;
        if(productId){
            this.sendRequest(productId)
        }
    },
    sendRequest: function(productId){
        var data = { 'id': productId,},
            self = this;
        Ajax('POST', this.url, data,
            function (response) {
                var responseJson = !!response ? JSON.parse(response) : {};
                if(!!responseJson?.product_id) {
                    self.onSuccess(responseJson);
                }
            },
            function (status, response) {
                var responseJson = JSON.parse(response);
                self.onError(status, responseJson);
            }
        );
    },
    onSuccess: function(responseJson){

    },
    onError: function(status, responseJson){

    }
};
