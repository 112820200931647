"use strict";

import Validation from './validation';
import Css from './css';
import Select from './select';

export default Form;

function Form(formElement){
    this.formElement = formElement;
    this.formValid = true;
    this.focusHasBeenSet = false;
}

Form.prototype = {
    init: function(){
        if(this.formElement !== null) {
            this.formElementsLength = this.formElement.elements.length;
            //disable form submit button if asked to do so
            var disableFormSubmitEl = Select.one('[data-disable-on-submit]', this.formElement),
                disableFormSubmitElValue = '';
            if(!!disableFormSubmitEl){
                disableFormSubmitEl.disabled = true;
            }

            this.Validation = Validation;
            for (var i = 0; i < this.formElementsLength; i++) {
                // get field
                var field = this.formElement.elements[i];

                // ignore buttons, fieldsets, etc.
                if (field.nodeName !== 'INPUT' && field.nodeName !== 'TEXTAREA' && field.nodeName !== 'SELECT')
                    continue;

                field.addEventListener('change', function (e) {
                    Form.prototype.checkFieldValidity(e.target);
                }, false);

                field = this.checkFieldValidity(field);

                if (!field.validity.valid)
                    this.formValid = false;
            }
            if( !!disableFormSubmitEl ){
                if( !this.formValid ){
                    disableFormSubmitEl.disabled = false;
                }
                else{
                    Css.addClass(disableFormSubmitEl, 'btn--disabled');
                }
            }
            return this.formValid;
        }
    },
    checkFieldValidity: function (field) {
        this.removeFormErrorFromField(field);
        var dataFieldValidation = field.getAttribute('data-validate'),
            validity = '';

        if (typeof field.willValidate !== 'undefined') {
            if (field.nodeName === 'INPUT' && field.type !== field.getAttribute('type')) {
                // native validation for field type not available use legacy validation
                field.validity.valid = this.checkLegacyValidity(field);
            }
            else {
                // Native Validation
                field.checkValidity();

                if (dataFieldValidation) {
                    switch (dataFieldValidation) {
                        case 'quantity':
                            validity = this.getQuantityValidity(field);
                            break;
                        case 'creditCard':
                            validity = this.getCreditCardValidity(field);
                            break;
                        case 'creditCardMonth':
                            validity = this.getCreditCardExpirationMonthValidity(field);
                            break;
                        case 'creditCardYear':
                            validity = this.getCreditCardExpirationYearValidity(field);
                            break;
                        case 'match':
                            validity = this.getMatchValidity(field);
                            break;
                        case 'noPoBox':
                            validity = this.getNoPoBoxValidity(field);
                            break;
                    }
                    field.setCustomValidity(validity);
                }
            }
        }
        else {
            field.validity.valid = this.checkLegacyValidity(field);// native validation not available
        }
        if (!field.validity.valid) {
            this.addFormErrorToField(field);
            if(!this.focusHasBeenSet)
                field.focus();
            this.focusHasBeenSet = true;
        }
        return field;
    },

    getNoPoBoxValidity: function (field){
        var validity = '';
        if ( Validation.isPoBox(field.value) )
            validity = 'This Address cannot be a P.O. Box.';
        return validity;
    },

    getCreditCardValidity: function (field) {
        var fieldPattern = field.getAttribute('pattern'),
            validity = '';
        if (field.value == '') {
            validity = 'Please fill out this field.';
        }
        else if (fieldPattern) {
            var pattern = new RegExp(fieldPattern);
            if (!pattern.test(field.value))
                validity = 'Please match the requested format.';
        }
        else if (!Validation.isCreditCardNumber(field.value)) {
            validity = 'Invalid Number';
        }
        return validity;
    },

    getCreditCardExpirationMonthValidity: function (field) {
        var fieldPattern = field.getAttribute('pattern'),
            fieldValidateWithField = field.getAttribute('data-validate-with'),
            validity = '',
            yearElement = null,
            year = 0,
            formEl = this.formElement;
        if(typeof formEl == 'undefined')
            formEl = field.form;
        if(fieldValidateWithField !== null)
            yearElement = formEl.elements[fieldValidateWithField];
        if(yearElement !== null)
            year = yearElement.value;
        if (field.value == '') {
            validity = 'Please fill out this field.';
        }
        else if(!year){
            validity = ''; // let this fall thru and the year check will catch if no year - this way an error wont appear when the have month selected, but no year.
        }
        else if (!Validation.isCreditCardMonth(field.value, year)) {
            validity = 'Invalid Month';
        }
        return validity;
    },

    getCreditCardExpirationYearValidity: function (field) {
        var fieldPattern = field.getAttribute('pattern'),
            validity = '';
        if (field.value == '') {
            validity = 'Please fill out this field.';
        }
        else if (!Validation.isCreditCardYear(field.value)) {
            validity = 'Invalid Year';
        }
        return validity;
    },

    getQuantityValidity: function (field) {
        var minimumQty = field.getAttribute('data-minimum'),
            multiplesOf = field.getAttribute('data-multiples-of'),
            allowZeroQty = field.hasAttribute('data-allow-zero-qty'),
            validity = '';

        if (!Validation.isMinQty(field.value, minimumQty, allowZeroQty))
            validity = 'Minimum quantity is ' + minimumQty;
        else if (!Validation.isQtyMultipleOf(field.value, minimumQty, multiplesOf))
            validity = field.value + ' is not a multiple of ' + multiplesOf;

        return validity;
    },

    getMatchValidity: function(field){
        var matchFieldName = field.getAttribute('data-match'),
            matchField = this.formElement.elements[matchFieldName],
            validityMessage = field.getAttribute('data-validity-message'),
            validity = '';
        if(matchField){
            if( matchField.value !== field.value )
                validity = validityMessage ? validityMessage : field.name + ' and ' + matchField.name + ' do not match';
        }
        return validity;
    },

    checkLegacyValidity: function (field) {
        field.validity = field.validity || {};
        if (field.disabled)
            field.validity.valid = true;
        else {
            field.validity.valid = true;
        }
        //still need to flesh this out if needed
        return field;
    },

    addFormErrorToField: function (field, errorMessage) {
        var el = field,
            message = errorMessage || field.validationMessage || '';
        Css.addClass(el, 'error');
        el.insertAdjacentHTML('afterend', '<small class="form__error" data-form-input-name="'+ field.name +'" >' + message + '</small>');
    },

    removeFormErrorFromField: function (field) {
        var el = field,
            nextSiblingElement = el.nextSibling;
        Css.removeClass(el, 'error');
        if (nextSiblingElement !== null) {
            if (nextSiblingElement.nodeName.toLowerCase() == 'small' && Css.hasClass(nextSiblingElement, 'form__error'))
                nextSiblingElement.parentNode.removeChild(nextSiblingElement);
        }
    }
};
