"use strict";

import Sanitation from './sanitation';

export default {
    isMinQty: function (qty, minQty, allowZeroQty) {
        var qty = Sanitation.toInt(qty),
            qty = isNaN(qty) ? '' : qty,
            minQty = Sanitation.toInt(minQty),
            allowZeroQty = allowZeroQty,
            result = true;

        switch (true) {
            case allowZeroQty && qty == 0:
                break;
            case qty == '':
                result = false;
                break;
            case qty < minQty:
                result = false;
                break;
        }
        return result;
    },
    isQtyMultipleOf: function (qty, minQty, multiplesOf) {
        var qty = Sanitation.toInt(qty),
            minQty = Sanitation.toInt(minQty),
            multiplesOf = Sanitation.toInt(multiplesOf),
            result = true;
        if (qty != minQty && (qty % multiplesOf) != 0)
            result = false;
        return result;
    },
    isCreditCardNumber: (function (arr) {
        return function (ccNum) {
            var len = ccNum.length,
                bit = 1,
                sum = 0,
                val;

            while (len) {
                val = parseInt(ccNum.charAt(--len), 10);
                sum += (bit ^= 1) ? arr[val] : val;
            }

            return sum && sum % 10 === 0;
        };
    }([0, 2, 4, 6, 8, 1, 3, 5, 7, 9])),
    isCreditCardMonth: function(monthSubmitted, yearSubmitted){
        var currentMonth = new Date().getMonth() + 1,
            currentYear  = new Date().getFullYear(),
            month = Sanitation.toInt(monthSubmitted),
            year = Sanitation.toInt(yearSubmitted);
        if(year > currentYear)
            return true;
        return year == currentYear && month >= currentMonth;
    },
    isCreditCardYear: function(yearSubmitted){
        var currentYear  = new Date().getFullYear(),
            year = Sanitation.toInt(yearSubmitted);
        return year >= currentYear;
    },
    isPoBox: function(string){
        return /(?:P(?:ost(?:al)?)?[\.\-\s]*(?:(?:O(?:ffice)?[\.\-\s]*)?B(?:ox|in|\b|\d)|o(?:ffice|\b)(?:[-\s]*\d)|code)|box[-\s\b]*\d)/i.test(string);
        //return /\bP(ost|ostal)?([ .]*O(ffice)?)?([ .]*Box)?\b/igm.test(string);
    },
    isFunction: function(fn){
        return fn && typeof(fn) === 'function' ? true : false;
    },
    getTrueType: function(el) {
        return Object.prototype.toString.call(el).slice(8, -1);
    }
};
