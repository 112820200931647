"use strict";

import Select from './select';
import Css from './css';

export default function(e, buttonElement){
    var buttonState = buttonElement.getAttribute('data-button-state');
    var expeditedShippingOptions = Select.all('[data-expedited-shipping-option]');
    if(expeditedShippingOptions !== null && expeditedShippingOptions.length){
        for (var i= 0, len=expeditedShippingOptions.length; i < len; i++){
            if(buttonState == 'open'){
                Css.addClass(expeditedShippingOptions[i], 'hide');
                buttonElement.setAttribute('data-button-state', '');
                e.target.innerHTML = '<i class="icon-down"></i> More Options';
            }
            else{
                Css.removeClass(expeditedShippingOptions[i], 'hide');
                buttonElement.setAttribute('data-button-state', 'open');
                e.target.innerHTML = '<i class="icon-up"></i> Hide Options';
            }
        }
    }
};
