"use strict";

export default {
    getOffsetRect: function (el) {
        var box = el.getBoundingClientRect(),
            body = document.body,
            docElem = document.documentElement,
            scrollTop = window.pageYOffset || docElem.scrollTop || body.scrollTop,
            scrollLeft = window.pageXOffset || docElem.scrollLeft || body.scrollLeft,
            clientTop = docElem.clientTop || body.clientTop || 0,
            clientLeft = docElem.clientLeft || body.clientLeft || 0,
            top = box.top + scrollTop - clientTop,
            right = box.right + scrollLeft - clientLeft,
            bottom = box.bottom + scrollTop - clientTop,
            left = box.left + scrollLeft - clientLeft;
        return {top: Math.round(top), right: Math.round(right), bottom: Math.round(bottom), left: Math.round(left)};
    },
    coordinatesToPositionAbsolutlyNextToElement: function(el, positionedParentEl, position){
        var t = 'auto',
            r = 'auto',
            b = 'auto',
            l = 'auto',
            position = position || 'bottom',
            elCoordinates = this.getOffsetRect(el),
            positionedParentCoordinates = this.getOffsetRect(positionedParentEl);
        switch(position){
            case 'bottom':
                t = (elCoordinates.bottom - positionedParentCoordinates.top) + 'px';
                l = (elCoordinates.left - positionedParentCoordinates.left) + 'px';
                break;
            case 'left':
                t = (elCoordinates.top - positionedParentCoordinates.top) + 'px';
                r = (positionedParentCoordinates.right - elCoordinates.left) + 'px';
                break;
            case 'right':
                t = (elCoordinates.top - positionedParentCoordinates.top) + 'px';
                l = (elCoordinates.right - positionedParentCoordinates.left) + 'px';
                break;
            case 'top':
                b = (positionedParentCoordinates.bottom - elCoordinates.top) + 'px';
                l = (elCoordinates.left - positionedParentCoordinates.left) + 'px';
                break;
            case 'over':
                t = (elCoordinates.top - positionedParentCoordinates.top) + 'px';
                l = (elCoordinates.left - positionedParentCoordinates.left) + 'px';
                break;
        }
        return {top: t, right: r, bottom: b, left: l };
    }
};
