"use strict";

import validateForms from './validateForms';
import Css from './css';
//import Emitter from './globalEmitter';

const validateAddingProductForms = function( context ){
    validateForms( '[data-js-validate-adding-product]', context, function(formElement){
        const inputElements = formElement.elements,
              innerHtmlFromSubmitButton = inputElements['add-item-to-cart'].innerHTML || null;
        formElement.submit();
        if( inputElements !== null && inputElements.length ){
            const formLoadingIndicatorTimeout = window.setTimeout(function(){
                inputElements['add-item-to-cart'].innerHTML = '';
                inputElements['add-item-to-cart'].setAttribute('disabled','');
                inputElements['qty'].setAttribute('disabled','');
                Css.addClass(inputElements['add-item-to-cart'], 'loading');
                Css.addClass(inputElements['add-item-to-cart'], 'loading--center');
                Css.addClass(inputElements['add-item-to-cart'], 'loading--no-style');
            }, 1000 );
        }
    } );
};

export { validateAddingProductForms };