"use strict";

import Css from './css';

const parentUntilQualifyingPosition = function(parent, qualifyingPositionArray){
    for ( ; parent !== null && parent !== document; parent = parent.parentNode ) {
        if( qualifyingPositionArray.indexOf(Css.getStyle(parent, 'position')) >= 0)
            break;
    }
    return parent;
};

const parentUntilAttribute = function(parent, attName){
    var returnEl = null;
    for ( ; parent !== null && parent !== document; parent = parent.parentNode ) {
        if( parent.hasAttribute(attName) ){
            returnEl = parent;
            break;
        }
    }
    return returnEl;
};

export {parentUntilQualifyingPosition, parentUntilAttribute};