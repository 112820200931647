"use strict";

import Ajax from './ajax';
import Select from './select';
import Css from './css';
import ToggleClass from './toggleClass';
import Emitter from './globalEmitter';
import Loading from './loading';

export default CartSummary;

function CartSummary(params){
    this.url = params.url || null;
    this.fetchData = params.fetchData || {};
    this.fetchMethod = params.fetchMethod || 'GET';
    this.summaryContainer = params.summaryContainer || null;
    this.summaryHtmlElement = null;
    this.loadingObj = new Loading();
    this.summaryLoadingElement = null;
    this.autoRefreshOnCartChange = params.autoRefreshOnCartChange || false;
    this.init();
}
CartSummary.prototype = {
    init: function(){

        this.createLoadingElement();
        this.addLoadingElementToDom();
        this.createSummaryHtmlElement();
        this.addSummaryToDom();

        var self = this;

        Emitter.on('cart-change', function (forceRefresh) {
            var refresh = forceRefresh || self.autoRefreshOnCartChange || false;
            if( refresh )
                self.refreshCartSummary(refresh);
            else
                self.destroyCachedSummaryHtml();
        });
    },
    setFetchParams: function(params){
        this.url = params.url || null;
        this.fetchData = params.data || {};
        this.fetchMethod = params.method || null;
    },
    fetchSummary: function(){
        this.startLoading();
        if( !this.hasCachedSummaryHTML() && this.url !== null ){
            var self = this;
            Ajax(this.fetchMethod, this.url, this.fetchData, function (response) {
                self.endLoading();
                var responseJson = JSON.parse(response);
                if (responseJson.data.hasOwnProperty('html')) {
                    self.onFetchSummarySuccess(responseJson);
                }
                else{
                    //console.log('error fetching cart summary', response);
                }
            });
        }
    },
    onFetchSummarySuccess: function(responseJson){
        this.setSummaryHtmlInnerHtml(responseJson.data.html);
        this.initializeDomEventsForSummary();
    },
    refreshCartSummary: function(forceRefresh){
        if(forceRefresh)
            this.destroyCachedSummaryHtml();
        if( !this.hasCachedSummaryHTML() )
            this.fetchSummary();
    },
    destroyCachedSummaryHtml: function(){
        this.summaryHtmlElement.innerHTML = '';
    },
    createLoadingElement: function(){
        this.summaryLoadingElement = document.createElement('div');
        Css.setStyle(this.summaryLoadingElement, {height: '35px', width: '35px', display: 'none'});
    },
    addLoadingElementToDom: function(){
        if(this.summaryLoadingElement.parentNode == null)
            this.summaryContainer.appendChild(this.summaryLoadingElement);
    },
    startLoading: function(){
        Css.setStyle(this.summaryLoadingElement, {display: 'block'});
        this.loadingObj.insertLoadingElement(this.summaryLoadingElement);
    },
    endLoading: function(){
        Css.setStyle(this.summaryLoadingElement, {display: 'none'});
        this.loadingObj.removeLoadingElement();
    },
    createSummaryHtmlElement: function(){
        this.summaryHtmlElement = document.createElement('div');
    },
    setSummaryHtmlInnerHtml: function(summaryHtml){
        if(this.summaryHtmlElement !== null) {
            this.summaryHtmlElement.innerHTML = summaryHtml;
        }
    },
    addSummaryToDom: function(){
        if(this.summaryHtmlElement.parentNode == null)
            this.summaryContainer.appendChild(this.summaryHtmlElement);
    },
    removeSummaryFromDom: function(){
        this.summaryHtmlElement.parentNode.removeChild(this.summaryHtmlElement);
    },
    initializeDomEventsForSummary: function(){
        this.setSummaryToggleClassElements();
    },
    setSummaryToggleClassElements: function(){
        var cartSummaryToggleElements = Select.all('[data-toggle]', this.summaryContainer);
        if(cartSummaryToggleElements !== null){
            if(cartSummaryToggleElements.length){
                for(var i= 0, len=cartSummaryToggleElements.length; i < len; i++){
                    new ToggleClass(cartSummaryToggleElements[i]);
                }
            }
        }
    },
    hasCachedSummaryHTML: function(){
        return this.summaryHtmlElement.innerHTML !== '';
    }
};
