"use strict";

export default {
    reverseString: function (s){
        return s.split('').reverse().join('');
    },
    titleCase: function(str){
        return str.replace(
            /\w\S*/g,
            function(txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }
        );
    },
    nextChar: function (char) {
        const charCode = char.charCodeAt(0);
        const isCapital = char === char.toUpperCase();
        if (isCapital) {
            return String.fromCharCode((charCode - 64) % 26 + 65);
        } else {
            return String.fromCharCode((charCode - 96) % 26 + 97);
        }
    },
    nextCharForLastInString: function(str){
        return `${str.slice(0,-1)}${this.nextChar(str.slice(-1))}`;
    },
};
