"use strict";

import Emitter from './globalEmitter';
import Notice from './notice';
import sampleRequest from './sample-request';

export default function(e, el){
    var sampleProductId = el.getAttribute('data-sample-product-id') || null;
    if(sampleProductId !== null) {
        sampleRequest.onError = function (status, response) {
            var notice = new Notice('error');
            notice.addMessage('<i class="icon-caution icon--alert"></i>' + response?.message || '');
            notice.autoRemoveTime = 3000;
            notice.addNoticeToDOM(e.target.parentNode);
            notice.addRemoveNoticeFromDOMTimeout();
        };
        sampleRequest.addToCart(sampleProductId, function (response) {
            Emitter.emit('cart-change');
            var notice = new Notice('success');
            notice.addMessage('<i class="icon-success icon--success"></i> Sample added to your cart');
            notice.autoRemoveTime = 3000;
            notice.addNoticeToDOM(e.target.parentNode);
            notice.addRemoveNoticeFromDOMTimeout();
        });
    }
};
