"use strict";

import Form from './form';
import Ajax from './ajax';
import Notice from './notice';
import Emitter from './globalEmitter';

const initializeQuickOrderSinglePidForms = function(quickOrderSinglePidForms){
        if(quickOrderSinglePidForms !== null){
            for( var i = 0, len = quickOrderSinglePidForms.length; i < len; i++ ){
                processQuickOrderSinglePidForm(quickOrderSinglePidForms[i]);
            }
        }
    };
const processQuickOrderSinglePidForm = function(quickOrderSinglePidForm){
        const formValidation = new Form(),
            productIdField = quickOrderSinglePidForm.elements["quick-order-pid"];

        productIdField.addEventListener('change', function(e){
            formValidation.removeFormErrorFromField(productIdField);
        }, false);

        quickOrderSinglePidForm.addEventListener('submit', function (e) {
            e.preventDefault();
            formValidation.removeFormErrorFromField(productIdField);
            if(productIdField.value == ''){
                formValidation.addFormErrorToField(productIdField, 'A valid item # is required');
            }
            else{
                Ajax('POST', window.location.origin + '/api/v1/carts', { pid: productIdField.value },
                    function (response) {
                        const responseJson = JSON.parse(response);
                        if(typeof responseJson.data == 'object') {
                            if (responseJson.data.hasOwnProperty('cart_rec_id')) {
                                var productDetails = responseJson.data;
                                Emitter.emit('cart-change', true);
                                productIdField.value = '';
                                const notice = new Notice('success');
                                notice.addMessage('<i class="icon-success icon--success"></i> Item added to your cart');
                                notice.autoRemoveTime = 3000;
                                notice.addNoticeToDOM(quickOrderSinglePidForm.parentNode);
                                notice.addRemoveNoticeFromDOMTimeout();
                                productIdField.focus();
                            }
                        }
                    },
                    function (status, response) {
                        const responseJson = JSON.parse(response),
                            message = 'Error adding item to cart';
                        if(responseJson.hasOwnProperty('message'))
                            message = responseJson.message;
                        formValidation.addFormErrorToField(productIdField, message);
                    }
                );
            }
        }, false);
    };

export default initializeQuickOrderSinglePidForms;
