"use strict";

import Css from './css';

export default Notice;

function Notice(noticeType){
    this.noticeType = 'success';
    this.parentElement = null;
    this.iconElement = null;
    this.headingElement = null;
    this.listElement = null;
    this.listItemsArray = [];
    this.messageElement = null;
    this.autoRemoveTime = 2000;
    this.removeNoticeFromDOMTimeout = null;
    this.init(noticeType);
}
Notice.prototype = {
    init: function(noticeType){
        this.setType(noticeType);
        this.createParentElement();
    },
    setType: function(noticeType){
        this.noticeType = noticeType || 'success';
    },
    createParentElement: function(){
        this.parentElement = document.createElement('div');
        Css.addClass(this.parentElement, 'notification');
        Css.addClass(this.parentElement, 'notification--absolute');
        Css.addClass(this.parentElement, 'notification--' + this.noticeType);
    },
    createIconElement: function(){
        this.iconElement = document.createElement('i');
        Css.addClass(this.iconElement, 'icon-' + this.noticeType );
    },
    addIconElement: function(){
        if(this.iconElement == null)
            this.createIconElement();
        this.addIconElementToParentElement();
    },
    iconElementIsAppendedToParent: function(){
        return  this.iconElement.parentNode !== null;
    },
    addIconElementToParentElement: function(){
        if( !this.iconElementIsAppendedToParent() )
            this.parentElement.appendChild(this.iconElement);
    },
    createHeadingElement: function(){
        this.headingElement = document.createElement('h4');
        Css.addClass(this.headingElement, 'notification__heading');
        Css.addClass(this.headingElement, 'notification__heading--' + this.noticeType);
    },
    setHeading: function(headingInnerHtml){
        this.headingElement.innerHTML = headingInnerHtml || '';
    },
    addHeading: function(headingInnerHtml){
        if(this.headingElement == null)
            this.createHeadingElement();
        this.setHeading(headingInnerHtml);
        this.parentElement.appendChild(this.headingElement);
    },
    createListElement: function(){
        this.listElement = document.createElement('ul');
        Css.addClass(this.listElement, 'notification__list');
    },
    addListItem: function(itemInnerHtml){
        if(this.listElement == null)
            this.createListElement();
        var listItem = document.createElement('li');
        Css.addClass(listItem, 'notification__list-item');
        listItem.innerHTML = itemInnerHtml;
        this.listItemsArray.push(listItem);
        this.listElement.appendChild(listItem);
        this.addListElementToParentElement();
    },
    addListElementToParentElement: function(){
        if( !this.listElementIsAppendedToParentElement() )
            this.parentElement.appendChild(this.listElement);
    },
    listElementIsAppendedToParentElement: function(){
        if(this.listElement !== null)
            return  this.listElement.parentNode !== null;
        return false;
    },
    createMessageElement: function(){
        this.messageElement = document.createElement('p');
        Css.addClass(this.messageElement, 'notification__message');
        Css.addClass(this.messageElement, 'notification__message--' + this.noticeType);
    },
    addMessage: function(messageInnerHtml){
        if(this.messageElement == null)
            this.createMessageElement();
        this.messageElement.innerHTML = messageInnerHtml;
        this.addMessageElementToParent();
    },
    addMessageElementToParent: function(){
        if( !this.messageIsAppendedToParent() )
            this.parentElement.appendChild(this.messageElement);
    },
    messageIsAppendedToParent: function(){
        return this.messageElement.parentNode !== null;
    },
    addNoticeToDOM: function(targetElement){
        if(!this.isInDOM()){
            if(targetElement !== null){
                targetElement.insertBefore(this.parentElement, targetElement.firstChild);
            }
        }

    },
    addRemoveNoticeFromDOMTimeout: function(callback){
        this.clearRemoveNoticeFromDOMTimeout();
        var self = this,
            callback = callback || function(){};
        if(this.autoRemoveTime > 0){
            this.removeNoticeFromDOMTimeout = setTimeout(function() {
                self.removeNoticeFromDOM();
                callback();
            }, this.autoRemoveTime);
        }
    },
    clearRemoveNoticeFromDOMTimeout: function(){
        if(this.removeNoticeFromDOMTimeout !== null)
            clearTimeout( this.removeNoticeFromDOMTimeout );
    },
    removeNoticeFromDOM: function(){
        if(this.isInDOM()) {
            this.parentElement.parentNode.removeChild(this.parentElement);
        }
    },
    isInDOM: function(){
        return this.parentElement.parentNode !== null;
    }
};
