"use strict";

import Ajax from './ajax';

export default {
    urlClicked: '/api/v1/a-dz/clicked/',
    urlShown: '/api/v1/a-dz/shown/',
    id: null,
    target: null,
    destination: null,
    sent: false,
    init: function(e, buttonElement){
        e.preventDefault();
        this.id = buttonElement.getAttribute('data-a-dz-id') || null;
        this.destination = buttonElement.getAttribute('data-a-dz-destination') || null;
        this.target = buttonElement.getAttribute('data-a-dz-target') || null;
        this.sent = false;
        if( this.target )
            this.send();
    },
    clicked: function(){
        this.update(this.urlClicked, this.id,{ "clicked": 1 });
    },
    shown: function(id){
        this.update(this.urlShown, this.id,{ "shown": 1 });
    },
    update: function(url, id, data){
        if(id){
            var self = this;
            Ajax('PUT', window.location.origin + url + id,
                data
                ,
                function (response) {
                    var responseJson = JSON.parse(response);
                    self.send();
                },
                function(status, response){
                    var responseJson = JSON.parse(response);
                    self.send();
                }
            );
        }
    },
    send: function(){
        if( !this.sent && !!this.destination ){
            this.sent = true;
            if( this.target )
                window.open(this.destination);
            else
                window.location = this.destination;
        }
    }
};
